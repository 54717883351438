<template>
  <div class="home">
    <b-container>
      <div class="row">
        <div class="col-lg-6 col-md-6 m-auto auth-form">
          <h3 class="text-center mb-4">Login</h3>
          <b-card>
            <div>
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onSubmit)">
                  <b-form-group label="Email" label-for="input-1">
                    <ValidationProvider
                      rules="required|email"
                      name="email"
                      v-slot="{ errors }"
                    >
                      <b-input-group>
                        <b-input-group-prepend>
                          <span class="input-group-text">
                            <b-icon icon="envelope"></b-icon>
                          </span>
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="email"
                          class="LoginInput"
                          size="lg"
                        ></b-form-input>
                      </b-input-group>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-form-group>

                  <b-form-group label="Password" label-for="password">
                    <ValidationProvider
                      rules="required"
                      name="password"
                      v-slot="{ errors }"
                    >
                      <b-input-group>
                        <b-input-group-prepend>
                          <span class="input-group-text">
                            <b-icon icon="key-fill"></b-icon>
                          </span>
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="password"
                          size="lg"
                          type="password"
                        ></b-form-input>
                      </b-input-group>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-form-group>

                  <b-row class="text-center">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                      <b-form-checkbox v-model="remember" value="1"
                        >Remember me</b-form-checkbox
                      >
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                      <router-link class="om-color" :to="{ name: 'password.reset' }"
                        >Forgot password?</router-link>
                    </div>
                  </b-row>

                  <div class="text-center mt-4">
                    <b-button
                      class="btn-om-solid"
                      type="submit"
                      size="lg"
                      :disabled="loading"
                    >
                      <span
                        class="
                          spinner-border spinner-border-sm
                          position-relative
                        "
                        style="top: -3px"
                        v-show="loading"
                      ></span>
                      LOGIN
                    </b-button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </b-card>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      email: "",
      password: "",
      remember: false,
      device_name: "web",
      message: "",
      loading: false,
    };
  },

  computed: {
    redirect_url() {
      const redirect_url = new URLSearchParams(window.location.search).get(
        "redirect_url"
      );
      return redirect_url ? window.location.origin + redirect_url : null;
    },
  },

  methods: {
    async onSubmit() {
      let data = {
        email: this.email,
        password: this.password,
        remember: this.remember,
        device_name: this.device_name,
      };
      this.loading = true;

      try {
        await this.$store.dispatch("auth/login", data);
          this.redirect_url
            ? (window.location.href = this.redirect_url)
            : this.$router.go(0);
      } catch (error) {
        if (error.status === 422) {
          this.$refs.form.setErrors(error.data.errors);
        } else {
          this.$refs.form.setErrors({
            email: ["Something wrong"],
          });
        }
      }

      this.loading = false;
    },
  },
};
</script>
